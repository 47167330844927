<template>
  <div class="mini_program_body">
    <left-big-title>{{$t('label.setup.mini.swap')}}</left-big-title>
    <!-- 表格组件 可从信息页面获取后端接口数据  再去组件页面渲染-->
    <dataTable :viewType="slideShow"></dataTable>
  </div>
</template>

<script>
import leftBigTitle from "./components/leftBigTitle";
import dataTable from './components/table'
export default {
  data() {
    return {
      slideShow:'slideShow'
    };
  },
  components: {
    leftBigTitle,
    dataTable
  },
};
</script>

<style lang="scss" scoped>
@import url("./style.css");
</style>
